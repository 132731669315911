import type { Ref}  from 'vue';
import { Url, $t, fileUtils as FileUtils } from 'o365-utils';

const { isPdfConversionSupported } = FileUtils;

export namespace AttachmentsUserInterface {
    export const createLoadingOverlay = () => {
        const overlay = document.createElement('div');
        overlay.style.top = '50px';
        overlay.style.height = 'calc(100% - 50px)';
        overlay.classList.add('overlay');

        const overlayInner = document.createElement('div');
        overlayInner.classList.add('overlay__inner');

        const overlayContent = document.createElement('div');
        overlayContent.classList.add('overlay__content');

        const spinner = document.createElement('span');
        spinner.classList.add('spinner');

        overlayContent.appendChild(spinner);
        overlayInner.appendChild(overlayContent);
        overlay.appendChild(overlayInner);
        
        return overlay;
    }

    export const removeOverlay = (container: HTMLDivElement) => {
        const overlay = container.querySelector('.overlay');

        if (overlay) {
            container.removeChild(overlay);
        }
    }

    export const createVideoControls = (videoElement: HTMLVideoElement) => {
        const container = document.createElement('div');
        container.style.width = '100%';
        container.style.height = '50px';
        container.style.background = 'rgba(255, 255, 255, 0.65)';
        container.classList.add('d-flex');

        // Play / Pause button section
        const playstate = document.createElement('div');
        playstate.style.width = '50px';
        playstate.style.cursor = 'pointer';
        playstate.classList.add('d-flex', 'justify-content-center', 'align-items-center');

        const playstateIcon = document.createElement('i');
        playstateIcon.style.marginTop = '2px';
        playstateIcon.classList.add('bi', 'bi-pause-fill', 'fs-3');
        playstate.appendChild(playstateIcon);

        container.appendChild(playstate);

        // Playbar section
        const playbar = document.createElement('div');
        playbar.classList.add('d-flex', 'flex-1', 'justify-content-center', 'align-items-center');
        
        const playbarInner = document.createElement('div');
        playbarInner.style.height = '15px';
        playbarInner.style.cursor = 'pointer';
        playbarInner.style.borderRadius = '100px';
        playbarInner.style.background = 'rgba(0, 0, 0, 0.3)';
        playbarInner.classList.add('w-100');
        
        const playbarContent = document.createElement('div');
        playbarContent.style.width = '0';
        playbarContent.style.height = '15px';
        playbarContent.style.borderRadius = '100px';
        playbarContent.style.transition = 'width 0.5s ease';
        playbarContent.style.background = 'rgba(0, 0, 0, 0.6)';
        playbarInner.appendChild(playbarContent);
        playbar.appendChild(playbarInner);

        container.appendChild(playbar);

        // Volume button section
        const volume = document.createElement('div');
        volume.style.width = '50px';
        volume.style.cursor = 'pointer';
        volume.classList.add('d-flex', 'justify-content-center', 'align-items-center', 'dropup', 'O365_SpotlightSlideVideoVolumeSliderToggle');
        
        const volumeIcon = document.createElement('i');
        volumeIcon.style.marginTop = '2px';
        volumeIcon.classList.add('bi', 'bi-volume-up-fill', 'fs-4');
        volume.appendChild(volumeIcon);

        const volumeDropup = document.createElement('div');
        volumeDropup.style.right = '10px';
        volumeDropup.style.bottom = '50px';
        volumeDropup.classList.add('dropdown-menu', 'O365_SpotlightSlideVideoVolumeSliderDropdown');
        
        const volumeSlider = document.createElement('input');
        volumeSlider.classList.add('form-slider');
        volumeSlider.setAttribute('type', 'range');
        volumeSlider.setAttribute('min', '0');
        volumeSlider.setAttribute('max', '1');
        volumeSlider.setAttribute('step', '0.01');
        volumeDropup.appendChild(volumeSlider);
        volume.appendChild(volumeDropup);

        container.appendChild(volume);

        // Controls listeners
        ['onclick', 'ontouchstart'].forEach(event => {
            //@ts-ignore
            playstate[event] = () => {
                videoElement.paused ? videoElement.play() : videoElement.pause();
            }
        });

        ['onclick', 'ontouchstart'].forEach(event => {
            //@ts-ignore
            playbarInner[event] = (e: MouseEvent | TouchEvent) => {
                if (videoElement.duration === Infinity || isNaN(Number(videoElement.duration))) {
                    return;
                }

                const { width, left } = playbarInner.getBoundingClientRect();
                const clientX = e instanceof TouchEvent ? e.touches[0].clientX : e.clientX;
                const navigatedDecimal = (clientX - left) / width;
                
                playbarContent.style.width = `${navigatedDecimal * 100}%`;
                videoElement.currentTime = videoElement.duration * navigatedDecimal;
            }
        });

        ['onclick', 'ontouchstart'].forEach(event => {
            //@ts-ignore
            volume[event] = (e: Event) => {
                if ([volume, volumeIcon].includes(<HTMLDivElement>e.target)) {
                    videoElement.muted = !videoElement.muted;
                }
            }
        });

        volumeSlider.oninput = (e: Event) => {
            const target = <HTMLInputElement>e.target;
            videoElement.volume = parseFloat(target.value);
        }

        // Specifically for updating volume icon state
        videoElement.onvolumechange = () => {
            const { muted, volume } = videoElement;

            // Remove all possible icons
            ['mute', 'off', 'down', 'up'].forEach(volumeState => {
                volumeIcon.classList.remove('bi-volume-$-fill'.replace('$', volumeState));
            });

            if (muted) {
                volumeIcon.classList.add('bi-volume-mute-fill');
            } else {
                if (volume === 0) {
                    volumeIcon.classList.add('bi-volume-off-fill');
                }

                if (volume <= 0.5) {
                    volumeIcon.classList.add('bi-volume-down-fill');
                } else {
                    volumeIcon.classList.add('bi-volume-up-fill');
                }
            }
        }

        videoElement.ontimeupdate = () => {
            // When the video reports infinity as duration (could be multiple reasons), render an infinite progress bar
            // that will change back to normal as soon as the video duration becomes available
            if (videoElement.duration === Infinity || isNaN(Number(videoElement.duration))) {
                playbarInner.classList.add('progress');
                playbarContent.style.width = '100%';
                playbarContent.style.background = '';
                playbarContent.classList.add('progress-bar', 'progress-bar-striped', 'progress-bar-animated', 'bg-secondary');
                return;
            } else {
                playbarInner.classList.remove('progress');
                playbarContent.style.background = 'rgba(0, 0, 0, 0.6)';
                playbarContent.classList.remove('progress-bar', 'progress-bar-striped', 'progress-bar-animated', 'bg-secondary');
            }

            const { currentTime, duration } = videoElement;
            const barLengthPercent = (currentTime / duration) * 100;
            
            playbarContent.style.width = `${barLengthPercent}%`;
        }

        videoElement.onplay = () => {
            playstateIcon.classList.add('bi-pause-fill');
            playstateIcon.classList.remove('bi-play-fill');
        }

        videoElement.onpause = () => {
            playstateIcon.classList.add('bi-play-fill');
            playstateIcon.classList.remove('bi-pause-fill');
        }

        videoElement.dispatchEvent(new Event('volumechange'));

        return container;
    }

    export const createUnsupportedSlide = (text: string, item: HTMLAnchorElement, fileName: string, originalFileType: string, triggerDownload: Function, container: HTMLDivElement) => {
        const primKey = item.dataset['primkey'];

        const explanationText = document.createElement('p');
        explanationText.classList.add('text-dark', 'fs-5', 'mb-4');
        explanationText.textContent = text;

        const filenameText = document.createElement('p');
        filenameText.classList.add('text-dark', 'fs-6', 'mb-4');
        filenameText.textContent = fileName;

        const buttonPrimaryDownload = document.createElement('button');
        
        if (isPdfConversionSupported(fileName)) {
            buttonPrimaryDownload.classList.add('btn', 'btn-primary', 'mb-1');
            const buttonPrimaryIcon = document.createElement('i');
            buttonPrimaryIcon.classList.add('bi', 'bi-download', 'me-1');
            buttonPrimaryDownload.appendChild(buttonPrimaryIcon);
            buttonPrimaryDownload.appendChild(document.createTextNode(`${$t('Download')} .PDF`));
        }

        const buttonSecondaryDownload = document.createElement('button');

        if (originalFileType !== 'pdf') {
            const buttonClass = buttonPrimaryDownload.childNodes.length > 0 ? 'btn-secondary' : 'btn-primary';
            buttonSecondaryDownload.classList.add('btn', buttonClass);
            const buttonSecondaryIcon = document.createElement('i');
            buttonSecondaryIcon.classList.add('bi', 'bi-download', 'me-1');
            buttonSecondaryDownload.appendChild(buttonSecondaryIcon);
            buttonSecondaryDownload.appendChild(document.createTextNode(`${$t('Download')} .${originalFileType.toUpperCase()}`));
        }

        [buttonPrimaryDownload, buttonSecondaryDownload].forEach(button => {
            button.onclick = () => {
                triggerDownload({ PrimKey: primKey, FileName: fileName }, button === buttonSecondaryDownload);
            }
        });

        [explanationText, filenameText, buttonPrimaryDownload, buttonSecondaryDownload].forEach(elem => {
            if (!isPdfConversionSupported(fileName) && elem === buttonPrimaryDownload) {
                return;
            }
            
            if (originalFileType === 'pdf' && elem === buttonSecondaryDownload) {
                return;
            }
            
            container.appendChild(elem);
        });

        container.classList.add('w-100', 'h-100', 'text-center', 'd-flex', 'flex-column', 'justify-content-center', 'align-items-center');
    }

    function getUrlSearchParams(path: string) {
        if (path.startsWith("/")) {
            path = window.origin + path;
        }
        const url = new URL(path);
        const searchParams = new URLSearchParams(url.search);
        return searchParams;
    }

    export const createFoxitSlide = (item: HTMLAnchorElement, viewName: string, iframe: HTMLIFrameElement, container: HTMLDivElement) => {
        let paramStr: any = item.href;

        if (paramStr.includes('?')) {
            paramStr = paramStr.split('?')[0];
        }

        paramStr = paramStr.split('/');

        const searchParams = getUrlSearchParams(item.href);
        
        // const fileName = paramStr.pop();
        const fileName = searchParams.get("file-name");
        const primKey = paramStr.pop();

        const params = { viewName: viewName, primKey, fileName };

        const url = `/nt/foxit9-viewer?params=${Url.decodObject(params)}`;

        iframe.onload = () => removeOverlay(container);
        iframe.src = url;

        container.appendChild(iframe);
    }

    export const createSettingsButton = (displayMode: Ref, DISPLAYMODES: { [key: string]: string },
    toggle360Button: Ref, toggleImageButton: Ref, settingsButton: Ref,
    refreshSpotlight: Function, toggleOptimizedImageSrc: Function) => {
        const trigger = document.createElement('i');
        trigger.classList.add('bi', 'bi-gear-fill');
        trigger.setAttribute('data-bs-toggle', 'dropdown');

        const dropdown = document.createElement('ul');
        dropdown.classList.add('dropdown-menu');
        
        const handleMedia360Toggle = () => {
            if (displayMode.value === DISPLAYMODES.MEDIA360) {
                displayMode.value = DISPLAYMODES.NORMAL;
                toggle360Button.value.innerHTML = $t('Turn on 360&deg; mode');
                
                refreshSpotlight();
            } else {
                displayMode.value = DISPLAYMODES.MEDIA360;
                toggle360Button.value.innerHTML = $t('Turn off 360&deg; mode');

                refreshSpotlight();
            }
        }

        toggle360Button.value = document.createElement('li');
        toggle360Button.value.classList.add('dropdown-item');
        toggle360Button.value.innerHTML = $t('Turn on 360&deg; mode');
        toggle360Button.value.onclick = handleMedia360Toggle;

        const handleImageToggle = () => {
            const isOptimized = toggleOptimizedImageSrc();
            
            if (isOptimized !== null) {
                toggleImageButton.value.textContent = isOptimized ? $t('Show original image') : $t('Show optimized image');
            }
        }

        toggleImageButton.value = document.createElement('li');
        toggleImageButton.value.classList.add('dropdown-item');
        toggleImageButton.value.textContent = $t('Show original image');
        toggleImageButton.value.onclick = handleImageToggle;

        dropdown.appendChild(toggle360Button.value);
        dropdown.appendChild(toggleImageButton.value);
        
        settingsButton.value.appendChild(trigger);
        settingsButton.value.appendChild(dropdown);
    }
}

export namespace AttachmentsEventHandlers {
    export const handleSpotlightControlsOverride = (isEditing: Ref, e: Event) => {
        if (isEditing.value) {
            e.stopImmediatePropagation();
        }
    }

    export const handleMedia360Controls = (displayMode: Ref, DISPLAYMODES: { [key: string]: string },
    media360InteractionVariables: any, media360Camera: any, media360Renderer: any, media360TargetContainer: any,
    isImage: Function, getFileNameFromUrl: Function, currentSpotlightItemOptions: Ref, e: TouchEvent) => {
        const target: any = e.target;
        if (target.type && target.type === 'range') {
            e.stopImmediatePropagation();
            return;
        }

        if (displayMode.value === DISPLAYMODES.MEDIA360) {
            e.stopImmediatePropagation();

            switch (e.type) {
                case 'mousedown':
                case 'touchstart':
                    handleMedia360SceneMouseDown(e, media360InteractionVariables);

                    if (e.touches && e.touches.length === 2) {
                        media360InteractionVariables.value.isPinching = true;
                    }

                    break;
                case 'mousemove':
                case 'touchmove':
                    handleMedia360SceneMouseMove(e, media360InteractionVariables, media360Camera);
                    break;
                case 'mouseup':
                case 'touchend':
                    media360InteractionVariables.value.isPinching = false;
                    media360InteractionVariables.value.lastPinchDistance = null;
                    media360InteractionVariables.value.isPointerDown = false;
                    break;
                case 'resize':
                    handleMedia360WindowResized(media360Camera, media360Renderer, media360TargetContainer, isImage, getFileNameFromUrl, currentSpotlightItemOptions);
                    break;
                case 'wheel':
                    handleMedia360SceneZoom(e, media360Camera);
                    break;
            }
        } else {
            if (e.type === 'touchend') {
                if (target.classList.contains('O365_SpotlightSlideDownloadButton')) {
                    target.click();
                }
            }
        }
    }

    const handleMedia360WindowResized = (media360Camera: any, media360Renderer: any, media360TargetContainer: any,
    isImage: Function, getFileNameFromUrl: Function, currentSpotlightItemOptions: Ref) => {
        if (media360Renderer.value !== null) {
            let { width, height } = media360TargetContainer.value.getBoundingClientRect();

            if (!isImage(getFileNameFromUrl(currentSpotlightItemOptions.value.id))) {
                height = parseInt(height, 10) - 95;
            }
            
            media360Camera.value.aspect = width / height;
            media360Camera.value.updateProjectionMatrix();
            media360Renderer.value.setSize(width, height);
        }
    }

    const handleMedia360SceneMouseDown = (e: MouseEvent | TouchEvent, media360InteractionVariables: any) => {
        e.preventDefault();

        media360InteractionVariables.value.isPointerDown = true;
        media360InteractionVariables.value.pointerX = e instanceof TouchEvent ? e.touches[0].clientX : e.clientX;
        media360InteractionVariables.value.pointerY = e instanceof TouchEvent ? e.touches[0].clientY : e.clientY;
        media360InteractionVariables.value.pointerDownLon = media360InteractionVariables.value.lon;
        media360InteractionVariables.value.pointerDownLat = media360InteractionVariables.value.lat;
    }

    const handleMedia360SceneMouseMove = (e: MouseEvent | TouchEvent, media360InteractionVariables: any, media360Camera: any) => {
        if (media360InteractionVariables.value.isPinching && e instanceof TouchEvent) {
            const dist = Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);
            
            if (media360InteractionVariables.value.lastPinchDistance !== null) {
                handleMedia360SceneZoom({ pinchDistance: media360InteractionVariables.value.lastPinchDistance - dist }, media360Camera);
            }

            media360InteractionVariables.value.lastPinchDistance = dist;
        } else if (media360InteractionVariables.value.isPointerDown) {
            const clientX = e instanceof TouchEvent ? e.touches[0].clientX : e.clientX;
            const clientY = e instanceof TouchEvent ? e.touches[0].clientY : e.clientY;

            media360InteractionVariables.value.lon = (clientX - media360InteractionVariables.value.pointerX) * -0.175 + media360InteractionVariables.value.pointerDownLon;
            
            let lat = (clientY - media360InteractionVariables.value.pointerY) * -0.175 + media360InteractionVariables.value.pointerDownLat;

            if (lat < -90) lat = -90;
            if (lat > 89) lat = 89;

            media360InteractionVariables.value.lat = lat;
        }
    }

    const handleMedia360SceneZoom = (e: any, media360Camera: any) => {
        let fov = media360Camera.value.fov;

        if (e.pinchDistance) { // Custom pinch zoom event
            fov += e.pinchDistance * 0.25;
        } else if (e.wheelDeltaY) { // WebKit
            fov -= e.wheelDeltaY * 0.05;
        } else if (e.wheelDelta) { // Opera
            fov -= e.wheelDelta * 0.05;
        } else if (e.detail) { // Firefox
            fov += e.detail;
        }

        if (fov < 30 || fov > 120) {
            fov = fov < 30 ? 30 : 120;
        }

        media360Camera.value.fov = fov;
        media360Camera.value.updateProjectionMatrix();
    }
}

export const {
    createLoadingOverlay,
    removeOverlay,
    createVideoControls,
    createUnsupportedSlide,
    createFoxitSlide,
    createSettingsButton,
} = AttachmentsUserInterface;

export const {
    handleSpotlightControlsOverride,
    handleMedia360Controls,
} = AttachmentsEventHandlers;